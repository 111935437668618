import axios from 'axios';
import {UserConfigModel} from '@/models/user/UserConfig';
import {ActionResult} from "@/models/ActionResult";
import {UserConfigGetByUserResult} from "@/models/userConfig/UserConfigGetByUserResult";
import {SearchResult} from "@/models/SearchResult";

export class UserConfigService {
    url = `${process.env.VUE_APP_CORE_URL}api/v1/`;
    urlCustomer = `${process.env.VUE_APP_CUSTOMER_URL}`;

    search(keyword: string, page: number, pageSize: number):
        Promise<SearchResult<UserConfigModel>> {
        return axios.get(`${this.url}userConfig`, {
            params: {
                keyword: keyword,
                page: page,
                pageSize: pageSize
            }
        });
    }

    update(config: UserConfigModel):
        Promise<any> {
        return axios.post(`${this.url}userConfig`, config);
    }

    changeRoles(userId: number, roleId: number):
        Promise<any> {
        return axios.post(`${this.url}userConfig/changerole`, {
            userId: userId,
            roleId: roleId
        });
    }

    getRoles():
        Promise<any> {
        return axios.get(`${this.url}role`);
    }

    getCates():
        Promise<any> {
        return axios.get(`${this.url}categories/getActiveByTypes`);
    }

    getUserConfig(userId: Number): Promise<ActionResult<UserConfigGetByUserResult>> {
        return axios.get(`${this.url}users/${userId}/config`);
    }

    getCustomerConfig(id: string): Promise<any> {
        return axios.get(`${this.url}users/${id}/customer-config`);
    }

    synsql2mongo():
        Promise<any> {
        return axios.post(`${process.env.VUE_APP_CORE_URL}api/v1/provinces/syn-sql-2-mongo`);
    }

    getPages(): Promise<any> {
        return axios.get(`${this.url}pages`);
    }

    getSaleChannel():
        Promise<any> {
        return axios.get(`${this.urlCustomer}api/v1/customer-groups/activated`);
    }
}
