import axios from 'axios';
import {SearchResult} from "@/models/SearchResult";
import {OptionItem} from "@/models/category/category";

export class PriceTableConfigService {
    url = `${process.env.VUE_APP_PRODUCT_URL}api/v1/priceTables`;

    search(keyword: string, page: number, pageSize: number):
        Promise<SearchResult<PriceTableConfig>> {
        return axios.get(this.url, {
            params: {
                keyword: keyword,
                page: page,
                pageSize: pageSize
            }
        });
    }

    update(priceTableConfig: PriceTableConfig):
        Promise<any> {
        return axios.post(`${this.url}`, priceTableConfig);
    }
}

export class PriceTableConfig {
    id: string | null;
    name: string;
    inventories: Array<ShortObj> = new Array<ShortObj>();
    saleChannels: Array<ShortObj> = new Array<ShortObj>();
    priceTableId: number;
}

export class ShortObj {
    id: string;
    name: string;
}